import { getNetworkDetails } from "src/constants";
import { Contract } from "@ethersproject/contracts";
import { toast } from "react-toastify";

export function sortAddress(add) {
  const sortAdd = `${add.slice(0, 6)}...${add.slice(add.length - 4)}`;
  return sortAdd;
}

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
}

export const swichNetworkHandler = async (ACTIVE_NETWORK) => {
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
    });
  } catch (error) {
    console.log("ERROR", error);
    toast.warn(error.message);
    if (error.code === 4902) {
      const data = getNetworkDetails(ACTIVE_NETWORK.toString());
      addNetworkHandler(data);
    }
  }
};

export const addNetworkHandler = async (NetworkDetails) => {
  try {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: NetworkDetails,
    });
  } catch (error) {
    console.log("ERROR", error);
    toast.warn(error.message);
  }
};

export function copyText(data) {
  navigator.clipboard.writeText(data);
  toast.info(`Copied ${data}`);
}
