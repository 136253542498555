import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  Divider,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { FaTelegramPlane, FaRedditAlien, FaTwitter } from "react-icons/fa";
import {} from "react-feather";
const useStyles = makeStyles((theme) => ({
  footerSection: {
    background: "#241043",
    paddingTop: "3rem",
    paddingBottom: "3rem",
  },

  textImg: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "2rem",
    "@media (max-width:602px)":{
      display:"flex",
      justifyContent:"center",
    },
  },
  imageBox: {
    border: "5px solid #E5E5E5",
    boxSizing: "border-box",
    borderRadius: "11px",
    marginTop: "-14px",
    background: "#fff",
    height: "70px",
    // width: "6%",
    minWidth:"65px",
    // width:"100px",
    position:"absolute",
    right:"26px",

    textAlign: "center",
  },

  TextSetting: {
    " & h1": {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "17px",
      textTransform: "capitalize",
      color: "#FFFFFF",
    },
    "& h2": {
      paddingTop: "1rem",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "17px",
      textTransform: "capitalize",
      color: "#FFFFFF",
    },
  },
  BabyCornerBox: {
    background:
      "linear-gradient(106.77deg, rgba(59, 13, 96, 0.4) 4.81%, rgba(129, 15, 148, 0.148) 98.47%)",
    backdropFilter: "blur(44px)",
    borderRadius: "30px",
paddingBottom: "1rem",
    width: "100%",
  },

  BabyCornerBoxText: {
    "&  h3": {
      paddingTop: "1rem",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "30px",
      lineHeight: "37px",
      textTransform: "capitalize",
      color: "#FFFFFF",
      textAlign: "initial",
      width: "68%",
    },
    "& h4": {
      paddingTop: "1rem",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "17px",
      textTransform: "capitalize",
      color: "rgba(255, 255, 255, 0.4)",
      textAlign: "initial",
    },
  },

  iconSetting: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "2rem",
    flexWrap: "wrap",
  },

  conrnerBox: {
    width: "65px",
    height: "65px",
    background: "rgba(0, 0, 0, 0.2)",
    borderRadius: "15px",
    color: "#D200A5",
  },
  socialMediaIcon: {
    width: "28px",
    height: "24px",
    margin: "13px",
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.footerSection}>
        <Container>
          <Box>
            <Box className={classes.textImg}>
              <Box className={classes.topcreators}>
                <img src=" ./images/l.png" alt="" />
              </Box>
            </Box>

            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Grid container>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Box className={classes.TextSetting}>
                      <Typography variant="h1">Marketplace</Typography>
                      <Typography variant="h2">Explore </Typography>
                      <Typography variant="h2">How it works</Typography>
                      <Typography variant="h2">Support </Typography>
                      <Typography variant="h2">Become a partner</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Box className={classes.TextSetting}>
                      <Typography variant="h1">Community</Typography>
                      <Typography variant="h2">RARI Token </Typography>
                      <Typography variant="h2">Discussion</Typography>
                      <Typography variant="h2">Voting </Typography>
                      <Typography variant="h2">Suggest feature</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Box className={classes.TextSetting}>
                      <Typography variant="h1">General</Typography>
                      <Typography variant="h2">Help Center </Typography>
                      <Typography variant="h2">Terms and Conditions</Typography>
                      <Typography variant="h2">Privacy Policy</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box className={classes.BabyCornerBox}>
                  <Container>
                    <Box className={classes.imageBox}>
                      <img
                        src=" ./images/light.png"
                        alt=""
                        style={{ width: "50%", marginTop: "13px" }}
                      />
                    </Box>
                    <Box className={classes.BabyCornerBoxText}>
                      <Typography variant="h3">Join our community</Typography>
                      <Box>
                        <Typography variant="h4" style={{ width: "68%" }}>
                          Become an invaluable part of our community, where you
                          can connect with the core Team and get more details
                          about Nafty. Join us and together let’s change the
                          adult industry for the better!
                        </Typography>
                      </Box>
                    </Box>

                    <Box className={classes.iconSetting}>
                      <Box className={classes.conrnerBox}>
                        <FaTelegramPlane className={classes.socialMediaIcon} />
                      </Box>
                      <Box className={classes.conrnerBox}>
                        <FaRedditAlien className={classes.socialMediaIcon} />
                      </Box>
                      <Box className={classes.conrnerBox}>
                        <FaTwitter className={classes.socialMediaIcon} />
                      </Box>
                      <Box className={classes.conrnerBox}>
                        <FaTwitter className={classes.socialMediaIcon} />
                      </Box>
                      <Box className={classes.conrnerBox}>
                        <FaTelegramPlane className={classes.socialMediaIcon} />
                      </Box>
                      <Box className={classes.conrnerBox}>
                        <FaRedditAlien className={classes.socialMediaIcon} />
                      </Box>
                    </Box>
                  </Container>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}
