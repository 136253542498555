export const NetworkContextName = "NETWORK";
export const ACTIVE_NETWORK = 97;
export const subscriptionContract =
  "0x35943D9d35804e911c64D76c4ec4Fc2449D2730a";

export const getNetworkDetails = (chianId) => {
  switch (chianId.toString()) {
    case "97":
      return [
        {
          chainId: "0x61",
          chainName: "Smart Chain - Testnet",
          nativeCurrency: {
            name: "Smart Chain - Testnet",
            symbol: "BNB",
            decimals: 18,
          },
          rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
          blockExplorerUrls: ["https://testnet.bscscan.com/"],
        },
      ];
  }
};
