import React from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
 import Footer from "./Footer";
 import TopBar from "./TopBar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: " #fff",
  },
  // MainLayout:{

  //   background: `linear-gradient(
  //     175.66deg
  //     , #3F0AB0 0.49%, #2E0450 53.12%)`,
  //   minHeight:"calc(100vh - 415px)"
  // },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  console.log(history.location);
  return (
    <div className={classes.root}>
       {/* <TopBar />  */}
      <div
        style={
          history.location.pathname !== "/"
            ? { display: "block" }
            : { display: "none" }
        }
      ></div>

      <div className={classes.MainLayout}>{children}</div>
       {/* <Footer />  */}
    </div>
  );
};

export default MainLayout;
