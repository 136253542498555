import React, { createContext, useEffect } from "react";
import { SUPPORTED_WALLETS } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";
import { ACTIVE_NETWORK } from "src/constants";
import { swichNetworkHandler } from "src/utils";

export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

export default function AuthProvider(props) {
  const { activate, chainId, account } = useWeb3React();

  let data = {
    updateUser: (account) => {
      setSession(account);
    },
    connectWallet: (data) => connectToWallet(data),
  };

  const connectToWallet = (data) => {
    try {
      const connector = data.connector;
      sessionStorage.setItem("walletName", data.name);
      if (connector && connector.walletConnectProvider?.wc?.uri) {
        connector.walletConnectProvider = undefined;
      }

      activate(connector, undefined, true).catch((error) => {
        if (error) {
          toast.error(JSON.stringify(error.message));
          window.sessionStorage.removeItem("walletName");
          activate(connector);
        }
      });
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (chainId && chainId != ACTIVE_NETWORK) {
      swichNetworkHandler(ACTIVE_NETWORK);
    }
  }, [chainId]);

  // useEffect(() => {
  //   const userAddress = window.sessionStorage.getItem("userAddress");
  //   if (userAddress) {
  //     data.connectWallet();
  //   }
  // }, []); //eslint-disable-line

  // useEffect(() => {
  //   if (window.sessionStorage.getItem("walletName")) {
  //     const selectectWalletDetails = SUPPORTED_WALLETS.filter(
  //       (data) => data.data.name == window.sessionStorage.getItem("walletName")
  //     );
  //     console.log("selectectWalletDetails", selectectWalletDetails);
  //     if (selectectWalletDetails[0]?.data) {
  //       connectToWallet(selectectWalletDetails[0].data);
  //     }
  //   }
  // }, []); //eslint-disable-line

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
